/* By Athlon Front-End Team */

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
//@import 'base/mixins';
//(imported in vars.scss)

/* Core variables */
@import 'base/vars';



/* Vendor */

/* Components */
@import '../components/header/header';
@import '../components/hero/hero';
@import '../components/video/video';
@import '../components/foundervideo/foundervideo';
@import '../components/productvideo/productvideo';
@import '../components/footer/footer';
@import '../components/button/button';
@import '../components/quote/quote';
@import '../components/list/list';
@import '../components/divider/divider';
@import '../components/iconlabel/iconlabel';
@import '../components/textcard/textcard';
@import '../components/features/features';
@import '../components/mobilemenu/mobilemenu';
@import '../components/contactform/contactform';
@import '../components/contactinfo/contactinfo';
@import '../components/checkbox/checkbox';
@import '../components/storytelling/storytelling';
@import '../components/scrollfadein/scrollfadein';
@import '../components/chartadervielfalt/chartadervielfalt';

/* Browser specific styles */
@import 'dirty/hacks';

html {
  // scroll-behavior: smooth;
  scroll-behavior: auto;
}


body {
  background: var(--white);
  color: var(--black);
  font-family: var(--noto-sans);
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0;

  @include respond-to(medium) {
    font-size: 1rem;
  }
}

* {
  &::selection {
    color: white;
    background: var(--violet-light);
  }
}

.resize-helper {
  position: fixed;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 100lvh;
  // border: 10px solid red;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}


h1 {
  font-size: var(--h1);
  margin: 0;
  padding: 0;
  font-family: var(--neue-montreal);
  // line-height: 95%;

  // @include respond-to(medium) {
  //   line-height: 90%;
  // }
  line-height: 120%;
}
h2 {
  font-size: var(--h2);
  margin: 0;
  padding: 0;
  font-family: var(--neue-montreal);
  line-height: 120%;
}
h3 {
  font-size: var(--h3);
  margin: 0;
  padding: 0;
  font-family: var(--neue-montreal);
  line-height: 120%;
}
.teaser-text {
  font-size: var(--teaser-text);
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: var(--neue-montreal);
  line-height: 125%;
}
.copy-large {
  font-size: var(--copy-large);
  margin: 0;
  padding: 0;
  line-height: 140%;
}
p, .copy-text {
  font-size: var(--copy-text);
  margin: 0;
  padding: 0;
  line-height: 140%;

  a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
.button-text {
  font-size: var(--button-text);
  // margin: 0;
  // padding: 0;
  font-weight: bold;
  line-height: 125%;

  @include respond-to(medium) {
    line-height: 140%;
  }
}
.label {
  font-size: var(--label);
  margin: 0;
  padding: 0;
  line-height: 140%;
}

strong {
  font-weight: bold;
}

.remove-height-on-desktop {
  @include respond-to(medium) {
    height: 0;
  }
}

/*
img, figure {
  width: 100%;
  display: inherit;
}

.video,
.img {
  position: relative;
  display: block;
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w) * var(--h));
  background: var(--color-black);
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
*/

main {
  // padding: 1rem;
  // min-height: 100vh;
  padding: 16px;
  // padding-top: 8rem;
  padding-top: 4rem;
  padding-bottom: 0;

  @include respond-to('medium') {
    padding: 32px;
    padding-top: 112px;
    // padding-top: 8rem;
    padding-bottom: 0;
  }

  @include respond-to('large') {
    padding: 32px;
  }
}

.hero-section {
  @include respond-to('large') {
    min-height: 90vh;
  }
}


.grid {
  // --columns: 12;
  --gutter: 16px;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  margin: var(--gutter) 0;
  align-items: center;

  @include respond-to('wide') {
    max-width: 1184px;
    margin: var(--gutter) auto;
  }
  @include respond-to('xl') {
    max-width: 1400px;
  }
  @include respond-to('xxl') {
    max-width: 1800px;
  }
}

@media only screen and (min-width: 720px) {
  .grid {
    --gutter: 32px;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gutter);
  }
  // .grid > .column {
  //   grid-column: span var(--columns);
  // }

}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  grid-column: span 12;

  @include respond-to('medium') {
    gap: 32px;

    &.column-6 {
      grid-column: span 6;
    }
  }

  @include respond-to('large') {
    &.column-4 {
      grid-column: span 4;
    }
  }
}


//general styles
.single-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  &.has-mobile {
    .desktop-image {
      display: none;
    }

    @include respond-to('medium') {
      .desktop-image {
        display: block;
      }
      .mobile-image {
        display: none;
      }
    }
  }
}
img {
  width: 100%;
}

svg {
  max-width: 100%;
}


ul {
  padding-left: 18px;
}
